@import '../../../styles/mixins';
@import '../../../styles/variables';

.mainContainer {
  @include verticalGap(12px);
  z-index: 1;

  @include sm {
    @include verticalGap(32px);
  }
}

.cardContainer {
  @include stepsContainer;
  @include verticalGap(30px);

  text-align: center;
}

.textContainer {
  @include verticalGap(20px);
}

.title {
  @include h2;
}

.description {
  @include subtitle;

  line-height: 22px;
  text-align: center;
}

.cardDisclaimerText {
  font-weight: $regularFontWeight;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: $black80;
  line-height: 22px;

  @include sm {
    align-self: center;
    max-width: 720px;
  }
}
