@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  width: 100%;
  padding-bottom: 24px;
}

.header {
  cursor: pointer;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  gap: 12px;

  .subtitle {
    @include body-text(md);
  }

  .icon {
    transition: transform 200ms linear;

    &.open {
      transform: rotate(90deg);
    }
  }
}

.content {
  overflow: hidden;
  transition: height 200ms linear;
}

.content.toggleDropdown {
  overflow: visible;
}

.list {
  padding: 16px;
  padding-bottom: 0;

  @include verticalGap(12px);
  align-items: start;

  .row {
    @include body-text(sm, 500);
    color: $black80;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:last-child {
      padding-bottom: 8px;
    }
  }
}
